import { PropsWithChildren, useEffect } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { useAuthorization } from "@/context";
import { appInsightsInstance, appInsightsPlugin } from "./applicationInsights";

export function ApplicationInsightsProvider({ children }: PropsWithChildren) {
  const { userId, name, username } = useAuthorization();

  useEffect(() => {
    if (appInsightsInstance && userId && name && username) {
      appInsightsInstance.setAuthenticatedUserContext(username);
      appInsightsInstance.addTelemetryInitializer((env) => {
        env.data = {
          ...env.data,
          ["ms-user"]: name,
          ["ms-username"]: username,
          ["ms-userid"]: userId
        };
      });
    }
  }, [name, userId, username]);

  return (
    <AppInsightsContext.Provider value={appInsightsPlugin}>
      <>{children}</>
    </AppInsightsContext.Provider>
  );
}

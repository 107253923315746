import { Dispatch, SetStateAction, useMemo, useEffect, useCallback } from "react";
import { BUTTON_LABELS } from "@/constants";
import { Box, Button, Grid, IconButton, InputAdornment, SelectChangeEvent, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Dialog, DialogAction } from "@/components/dialogs";
import { ClearingAllocation, DisciplineAuthorisationCommentValue } from "@/interfaces";
import { useForm } from "react-hook-form";
import { AppFormDateInput, AppFormInput, AppFormSelect } from "@/components/fields";
import { useInstrumentIdList, useRestrictedClearingList } from "@/hooks";
import { nvcpClearingAllocationSchema } from "@/validations";
import { useARContext, useAuthorization } from "@/context";
import dayjs from "dayjs";
import { throttle, isEqual } from "lodash";
import { NVCPInstrumentDropdown } from "./NVCPInstrumentSelect";
import { Clear } from "@mui/icons-material";

interface NVCPClearingAllocationTableToolbarProps {
  setModalDialogAction: Dispatch<SetStateAction<"Add" | "Edit" | "View" | undefined>>;
  modalDialogAction: "Add" | "Edit" | "View" | undefined;
  disabled: boolean;
  rowIsSelected: boolean;
  selectedRow: ClearingAllocation;
  setSelectedRow: Dispatch<SetStateAction<ClearingAllocation>>;
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  clearingAllocations: ClearingAllocation[];
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isViewMode?: boolean;
  refreshAvailableBalance: () => void;
}

export function NVCPClearingAllocationTableToolbar({
  setModalDialogAction,
  modalDialogAction,
  disabled,
  rowIsSelected,
  selectedRow,
  setSelectedRow,
  disciplineAuthCommentValue,
  clearingAllocations,
  onUpdate,
  isViewMode,
  refreshAvailableBalance
}: NVCPClearingAllocationTableToolbarProps) {
  const { userId, username } = useAuthorization();

  const defaultValues: ClearingAllocation = {
    id: crypto.randomUUID(),
    referenceNo: "",
    clearingAllocation: null,
    noClearingAfterDate: undefined,
    expiryDate: undefined,
    restrictedClearingId: "",
    restrictedClearing: "",
    restrictedClearingAllocation: null,
    termsAndConditions: "",
    createdBy: userId,
    createdByName: username
  };

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset
  } = useForm<ClearingAllocation>({ defaultValues });

  const selectedInstrument = watch("referenceNo");
  const restrictedClearingId = watch("restrictedClearingId");

  const { arMap } = useARContext();
  const {
    data: instrumentIdList,
    isFetching: isFetchingInstrumentIdList,
    refetch: refetchInstrumentIdList
  } = useInstrumentIdList(arMap?.id ?? "");
  const { data: restrictedClearingList, refetch: refetchRestrictedClearingList } =
    useRestrictedClearingList(selectedInstrument);

  const throttleRefetchInstrumentIdList = useCallback(throttle(refetchInstrumentIdList, 8000, { leading: true }), []);
  const modalDialogIsOpen = modalDialogAction !== undefined;

  const formValues = watch();

  const applyButtonEnabled = useMemo(() => {
    if (modalDialogAction === undefined) {
      return;
    }

    try {
      nvcpClearingAllocationSchema.validateSync(getValues(), { abortEarly: false });
    } catch (_error) {
      return false;
    }

    if (modalDialogAction === "Edit") {
      const valuesEqual = isEqual(selectedRow, formValues);

      if (valuesEqual) {
        return false;
      }
    }

    return true;
  }, [modalDialogAction, selectedRow, formValues]);

  const handleDialogOpen = (action: "Add" | "Edit" | "View") => {
    throttleRefetchInstrumentIdList();
    if (action === "Edit" || action === "View") {
      reset(selectedRow);
    } else {
      reset(defaultValues);
    }
    setModalDialogAction(action);
  };

  const handleDialogClose = () => {
    setModalDialogAction(undefined);
  };

  const handleAdd = () => {
    const addedFormValues = formValues;

    addedFormValues.created = dayjs().utc().toDate();

    const newClearingAllocation = [addedFormValues, ...clearingAllocations];

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(newClearingAllocation, (_k, v) => (v === undefined ? null : v))
    });

    handleDialogClose();
  };

  const handleEdit = () => {
    const editedFormValues = formValues;

    editedFormValues.lastModified = dayjs().utc().toDate();
    editedFormValues.lastModifiedBy = userId;
    editedFormValues.lastModifiedByName = username;

    const updatedClearingAllocation = clearingAllocations.map((row) =>
      row.id === editedFormValues.id ? editedFormValues : row
    );
    setSelectedRow(editedFormValues);
    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(updatedClearingAllocation)
    });

    handleDialogClose();
  };

  const handleDelete = () => {
    const filteredClearingAllocations = clearingAllocations.filter((row) => row.id !== selectedRow.id);

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(filteredClearingAllocations)
    });
  };

  const renderRefreshButton = () => {
    return (
      <Button
        variant="text"
        color="secondary"
        data-testid="nvcp-table-refresh-button"
        onClick={() => refreshAvailableBalance()}
        disabled={clearingAllocations.length === 0}
      >
        Refresh
      </Button>
    );
  };

  const getTitleName = () => {
    if (modalDialogAction === "Add") {
      return "Add Clearing Mechanism";
    } else if (modalDialogAction === "View") {
      return `CPS: ${selectedInstrument}`;
    } else {
      return "Update Clearing Mechanism";
    }
  };

  const actionButtonList: DialogAction[] = [
    {
      label: BUTTON_LABELS.CANCEL,
      onClick: handleDialogClose,
      disabled: false
    },
    {
      label: BUTTON_LABELS.APPLY,
      disabled: !applyButtonEnabled,
      onClick: modalDialogAction === "Add" ? handleAdd : handleEdit
    }
  ];

  useEffect(() => {
    refetchRestrictedClearingList();
  }, [selectedInstrument, refetchRestrictedClearingList]);

  function ClearAdornment({ clearField }: { clearField: () => void }) {
    return (
      <InputAdornment sx={{ marginRight: 2 }} position="end">
        <IconButton size="small" onClick={() => clearField()}>
          <Clear />
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography fontSize={"1.125rem"}>Clearing Mechanisms</Typography>
      </Box>
      <Box justifyContent={"flex-end"}>
        {isViewMode ? (
          <>
            <Button
              variant="text"
              color="secondary"
              data-testid="nvcp-table-view-button"
              disabled={!rowIsSelected}
              onClick={() => handleDialogOpen("View")}
            >
              View
            </Button>
            {renderRefreshButton()}
          </>
        ) : (
          <>
            <Button
              variant="text"
              color="secondary"
              data-testid="nvcp-table-add-button"
              disabled={disabled}
              onClick={() => handleDialogOpen("Add")}
            >
              Add
            </Button>
            <Button
              variant="text"
              color="secondary"
              data-testid="nvcp-table-edit-button"
              disabled={!rowIsSelected || disabled}
              onClick={() => handleDialogOpen("Edit")}
            >
              Edit
            </Button>
            <Button
              variant="text"
              color="secondary"
              data-testid="nvcp-table-delete-button"
              disabled={!rowIsSelected || disabled}
              onClick={handleDelete}
            >
              Delete
            </Button>
            {renderRefreshButton()}
          </>
        )}

        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          data-testid={"clearing-allocation-modal"}
          open={modalDialogIsOpen}
          onClose={handleDialogClose}
          title={getTitleName()}
          actions={modalDialogAction !== "View" ? actionButtonList : []}
          showCloseIcon={isViewMode}
        >
          <Grid container spacing={{ xs: 1, sm: 2 }} columns={12} sx={{ pt: 1 }}>
            <Grid item xs={6}>
              <NVCPInstrumentDropdown
                name={"referenceNo"}
                control={control}
                label={"Clearing instrument"}
                options={instrumentIdList}
                data-testid="nvcp-instrument-reference-no"
                onChangeHandler={() => {
                  setValue("restrictedClearingAllocation", defaultValues.restrictedClearingAllocation);
                }}
                readOnly={isViewMode}
                isLoading={isFetchingInstrumentIdList}
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormInput
                control={control}
                label={"Clearing allocation (ha)"}
                name={"clearingAllocation"}
                error={errors.clearingAllocation}
                data-testid="input-clearing-allocation"
                showError={false}
                type="number"
                disabled={!Boolean(selectedInstrument)}
                readOnly={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormDateInput
                name={"noClearingAfterDate"}
                control={control}
                error={errors.noClearingAfterDate}
                data-testid="date-no-clearing-after"
                showError={false}
                label={"No clearing after"}
                disabled={!Boolean(selectedInstrument)}
                readOnly={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormDateInput
                name={"expiryDate"}
                control={control}
                error={errors.expiryDate}
                data-testid="date-expiry-date"
                showError={false}
                label={"Expiry"}
                disabled={!Boolean(selectedInstrument)}
                readOnly={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormSelect
                name={"restrictedClearingId"}
                control={control}
                error={errors.restrictedClearingId}
                data-testid="select-restricted-clearing-id"
                label={"Restricted clearing"}
                options={restrictedClearingList}
                isOptional={true}
                showError={false}
                onChangeHandler={(event) => {
                  const value = (event as SelectChangeEvent).target.value;
                  setValue("restrictedClearing", restrictedClearingList.filter((row) => row.id === value)[0].value);
                }}
                disabled={!Boolean(selectedInstrument)}
                readOnly={isViewMode}
                endAdornment={
                  restrictedClearingId && !isViewMode ? (
                    <ClearAdornment
                      clearField={() => {
                        setValue("restrictedClearingId", defaultValues.restrictedClearingId);
                        setValue("restrictedClearing", defaultValues.restrictedClearing);
                        setValue("restrictedClearingAllocation", defaultValues.restrictedClearingAllocation);
                      }}
                    />
                  ) : undefined
                }
              />
            </Grid>
            <Grid item xs={6}>
              <AppFormInput
                control={control}
                label={"Restricted clearing allocation (ha)"}
                name={"restrictedClearingAllocation"}
                error={errors.restrictedClearingAllocation}
                data-testid="input-restricted-clearing-allocation"
                showError={false}
                type="number"
                disabled={!Boolean(selectedInstrument) || !Boolean(restrictedClearingId)}
                readOnly={isViewMode}
              />
            </Grid>
            <Grid item xs={12}>
              <AppFormInput
                control={control}
                label={"Terms and Conditions"}
                name={"termsAndConditions"}
                error={errors.termsAndConditions}
                data-testid="input-terms-and-conditions"
                showError={false}
                multiline={true}
                rows={5}
                disabled={!Boolean(selectedInstrument)}
                readOnly={isViewMode}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    </GridToolbarContainer>
  );
}
